import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

const Footer = () => (
  <footer>
    <Container>
      <Row className="align-items-center justify-content-between flex-column flex-sm-row">
        <Col>
          <p className="mb-1">
            © {new Date().getFullYear()} Toureka! Inc. All Rights Reserved.
          </p>
        </Col>
        {/* <Col xs="auto">
          <Link to="/privacy">Privacy Policy</Link>
          <span className='mx-1'>·</span>
          <Link to="/terms">Terms & Conditions</Link>
        </Col> */}
      </Row>
    </Container>
  </footer>
)

export default Footer
